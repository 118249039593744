// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

//Font awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datatable BS4
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

@import 'evo-calendar';

@import 'calendar';
@import 'header';
@import 'account';
@import 'twilio';
@import 'chat';

:focus {
    outline: unset!important;
}
body {
    overflow-x: hidden;
    letter-spacing: 1px;
    min-width: 320px;

    .btn {
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #FFFFFF;
    }
    .btn-link,
    .btn-primary {
        color: #000000;
    }
}

.bg-black {
    background-color: #000000;
}
.navbar-brand img {
    height: 50px;
}
.action-btn {
    font-size: 22px;
    border: none;
    background: none;
}

.edit-plan-program {
    border: none;
    background: none;
    color: $warning;
}
.delete-plan-program,
.delete-action {
    border: none;
    background: none;
    color: $danger;
}


 .page .ck-content {
    min-height: 400px !important;
    max-height: 400px !important;
 }


 ul.plan-programs{
     list-style: none;
     margin: 0;
     padding: 0;
     li {
        margin: 0 0 10px;
        padding: 5px 30px 5px 10px;
        border: 1px solid #CCC;
        position: relative;
        .delete-plan-program {
            position: absolute;
            right: 0;
            top: 0;
        }
     }
 }

 .article {
     margin-bottom: 50px;
 }

 .article-content {
     img {
         max-width: 100%;
     }
 }

 .card.page-content {
     min-height: calc(100vh - 250px);
     border: 0;
     background-color: #FFFFFF;
     .card-header {
         background-color: $primary;
         border-radius: 15px;
     }
 }

 .icon {
     display: block;
     width: 40px;
     height: 40px;
     background-repeat: no-repeat;;
     background-position: center center;
     margin: 0 auto 10px;
     &.heart {
         background-image: url('../images/icon-heart.png');
     }
     &.men {
         background-image: url('../images/icon-men.png');
     }
     &.time {
         background-image: url('../images/icon-time.png');
     }
 }

 .page-thumb {
     max-height: 400px;
     max-width: 100%;
     &.default {
         border: 1px solid #CCC;
     }
 }

 hr {
     border-color: $primary;
     border-width: 3px;
     &.small {
         border-width: 1px;
     }
     &.grey {
         border-color: #CCC;
     }
 }

 .pagination {
    justify-content: center;
 }

 .page-title {
     color: #000;
     &:hover {
         color: inherit;
     }
 }

 .planselect-input {
     display: inline-block;
    width: 50px;
    text-align: center;
    font-weight: 600;
 }
