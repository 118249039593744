 
.plan-item {
    border: 2px solid $primary;
    padding: 10px;
    margin-bottom: 20px;
    span {
        display: block;
        line-height: 1.5rem;
        font-size: 1.1rem;
        b {
            font-size: 1.3rem;
        }
    }
    .total-price {
        display: inline;
    }
    &:hover {
        background-color: rgba($primary,0.3);
    }
}


//rating

#exercise-rate-container {
    input {
        height: 50px;
        width: 50px;
        margin: 5px;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        &:after {
            content: " ";
            background: url('../images/mood.png') no-repeat;
            display: block;
            height: 50px;
            width: 50px;
            background-position-x: -203px;
            cursor: pointer;
            opacity: 0.5;
        }
        &.rate-5:after {
            background-position-x: 0px;
        }
        &.rate-4:after {
            background-position-x: -50px;
        }
        &.rate-3:after {
            background-position-x: -103px;
        }
        &.rate-2:after {
            background-position-x: -153px;
        }
        &:focus {
            outline: none;
        }

        &:checked,
            &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}


.stat-item{
    margin: 10px auto 20px;
    width: 200px;
    height: 200px;
    padding: 20px;
    background: url('../images/stats-circle.png') center center no-repeat;
    text-align: center;
    font-size: 30px;
    line-height: 35px;
    display:flex;
    align-items:center;
    justify-content: center;
}

.user-exercise-index-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 40px;
}

.account-setting-container {
    max-width: 600px;
    margin: 0 auto;
    label {
        font-weight: 600;

    }
    select.form-control,
    input.form-control {
        background-color: $primary;
        font-weight: 600;
        border: none;
        border-radius: 5px;
    }
}
.list-group.challenges {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px;
    li.list-group-item {
        border: none;
        font-weight: 700;
        font-size: 16px;
        span.badge {
            border-radius: 0;
            width: 150px;
            max-width: 100%;
        }
    }
}