.calendar-week {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    li { 
        flex-wrap: nowrap; 
        border: 1px solid #000;
        padding: 15px;
        flex-grow: 1;
        flex-basis: 0;
        min-height: 300px;
        text-align: center;
        position: relative;
        .day-header {
            font-size: 12px;
            b {
                font-size: 14px;
            }
        }
        .time-add,
        .calendar-add {
            position: absolute;
            bottom: 15px;
            right: 15px;
            
        }
    }
    .day-plan {
        border: 1px solid $primary;
        margin-bottom: 5px;
        padding: 3px;
        background: rgba($primary, 0.6);
        color: #000;
        font-size: 13px;
        line-height: 15px;
        padding-right: 20px;
        .plan-time {
            font-size: 12px;
        }
        .delete-time,
        .delete-plan {
            padding:1px 2px;
            border: none;
            background: $danger;
            font-size: 12px;
            color: #FFF;
            position: absolute;
            right: 0px;
            top: -5px;
            margin-top: 20px;
        }
        .edit-time,
        .edit-plan {
            padding:1px 2px;
            border: none;
            background: $warning;
            font-size: 12px;
            color: #FFF;
            position: absolute;
            right: 0px;
            top: -5px;
            margin-top: 0px;
        }
        .edit-time, .delete-time {
            position: relative;
            top: 0;
            margin-top: 5px;
        }
    }
    .day-container .day-plan {
        padding: 5px;
    }
}