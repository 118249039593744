#twilio-container {
    position: relative;
    width: 100%;
    min-height: 600px;
    background: #000;
    
    .status {
        position: absolute;
        top: 40%;
        color: #FFF;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    
    .buttons {
        position: absolute;
        bottom: 20px;
        left: 50%;
        margin-left: -100px;
        width: 200px;
        text-align: center;
        .btn {
            width: 35px;
            height: 35px;
            padding: 0;
            text-align: center;
            margin: 0 5px;
            font-size: 20px;
            border-radius: 15px;
        }
    }

    .video-name {
        color: #FFF;
        position: absolute;
        top: 10px;
        left: 10px;
    }

    video {
        max-width: 100%;
    }

    .my-video {
        max-width: 200px;
        position: absolute;
        bottom: 20px;
        right: 30px;
        border: 2px solid #FFF;

    }

    .video-container {
        width: 100%;
    }
}

