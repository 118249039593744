nav {
    .nav-link {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 1rem;
    }
}

.settings {
    &.nav-item {
        &.dropdown {
            .nav-link {
                &.dropdown-toggle{
                    &:after {
                        background-image: url('../images/icon-setting.png');
                        background-size: 100%;
                        background-repeat: no-repeat;
                        border: 0;
                        width: 13px;
                        height: 13px;
                        opacity: 0.7;
                        vertical-align: inherit;
                    }
                }

                &:hover, &:focus {
                    &.dropdown-toggle{
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.show {
        &.nav-item {
            &.dropdown {
                .nav-link {
                    &.dropdown-toggle{
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.notify-message {
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
    padding: 15px;
    position: relative;
    font-size: 20px;
    line-height: 25px;
    z-index: 2;

    h3 {
        font-size: 30px;
        line-height: 40px;
        text-transform: uppercase;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 2px;
        font-size: 25px;
        color: #FFF;
        opacity: 1;
    }

    &:after {
        content: " ";
        position: absolute;
        width: 100vw;
        height: 100%;
        background-image: url('../images/message-bg.jpg');
        background-size: cover;
        top: 0;
        left: calc(50% - 50vw);
        z-index: -1;
    }
}

.card-header {
    h1, h2, h3 {
        margin-bottom: 0;
    }
}

.header-bg {
    width: 100%;
    padding-top: 20%;
    background-size: cover;

    &.type-1 {
        background-image: url('../images/header-bg1.png');
    }

    &.type-2 {
        background-image: url('../images/header-bg2.png');
    }
}

#page-footer {
    background-color: #000000;
    padding: 20px 0;

    a {
        color: #FFFFFF;
        line-height: 60px;
    }
}

#exercise-header {
    margin-top: -30px;

    .eh-items {
        list-style: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 768px) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .eh-item {
            border-bottom: 1px solid #e5e5e5;
            color: $primary;
            background-color: #FFFFFF;
            text-align: center;
            padding: 10px;
            margin: 0;
            width: 33%;
            font-weight: 600;

            @media screen and (max-width: 768px) {
                width: 100%;
                border: 1px solid #e5e5e5;
                margin: -1px;
            }

            span {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .eh-item:nth-child(2) {
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;
        }
    }
}
