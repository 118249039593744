/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Sep 3, 2021, 8:30:36 AM
    Author     : Pampyras
*/
#chat-messages-container {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 30px;
}
#chat-messages {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 600px;
    margin: 20px auto;
    li:after {
        content: " ";
        clear: both;
        display: block;
    }
    div.message-container {
        border-radius: 20px;
        border-bottom-left-radius: 0;
        background-color: #eeeeee;
        border: 1px solid #dfdfdf;
        float: left;
        padding: 10px;
        margin-bottom: 30px;
        position: relative;
        .time {
            position: absolute;
            top: -18px;
            font-size: 9px;
            left: 10px;
            width: 200px;
        }
        .sender {
            display: none;
        }
        .receiver {
            display: none;
        }
    }
    
    div.message-container.mine {
        border-radius: 20px;
        border-bottom-right-radius: 0;
        background-color: #1a4aa4;
        border: 1px solid #1a4aa4;
        float: right;
        color: #FFFFFF;
        .time {
            right: 10px;
            left: auto;
            text-align: right;
            color: #000;
        }
        
    }
    
}
